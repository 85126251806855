import React from "react";

import "./../scss/live.scss";

function LiveIndicator({ className = undefined }) {
    let classNames = ["livenow"];
    if(className) {
        classNames.push(className);
    }
    return (
        <div className={ classNames.join(" ") }>
            <div/>
            <div/>
            <div/>
        </div>
    )
}

export default LiveIndicator;
