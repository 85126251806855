import React from "react";
import {
    Annotation
} from "@zandor300/react-apple-mapkitjs";
import {
    Easing,
    Tween
} from "@tweenjs/tween.js";
import Skeleton from "react-loading-skeleton";

import AppleMapsMap from "./apple-maps/AppleMapsMap";
import WidthProvider from "./WidthProvider";
import getCenterCoordinate from "../utilities/getCenterCoordinate";
import getAnnotationGlyphImage from "../utilities/getAnnotationGlyphImage";
import getAnnotationGlyphColor from "../utilities/getAnnotationGlyphColor";

class LiveAppleMaps extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            height: 0,
            interpolatedCoordinates: {},

            centerLatitude: null,
            centerLongitude: null,
            latitudeSpan: null,
            longitudeSpan: null
        }
        this.centerTween = null;
        this.trackerTweens = {};
        this.updateSize = this.updateSize.bind(this);
    }

    componentDidMount() {
        this.updateSize();

        window.addEventListener("resize", this.updateSize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateSize)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.trackers !== this.props.trackers) {
            const animated = prevProps.trackers && this.props.trackers;
            this.setPositions(this.props.trackers, animated);
        }
        if(prevProps.width !== this.props.width) {
            this.updateSize();
        }
    }

    updateSize() {
        this.setState(() => {
            return {
                height: (window.innerHeight - 190) + "px",
                width: this.props.width === 1280 ? "100%" : this.props.width + "px"
            }
        });
    }

    shouldTestMovement() {
        return window.location.search === "?debug"
    }

    setPositions(trackers, animated) {
        this.updateSize();
        if(!trackers) {
            return;
        }

        if(this.shouldTestMovement()) {
            for(let i = 0; i < trackers.length; i++) {
                trackers[i].latitude += (Math.random() - 0.5) * 0.005;
                trackers[i].longitude += (Math.random() - 0.5) * 0.005;
            }
        }

        const targetCenter = getCenterCoordinate(trackers);
        if(!animated) {
            this.setState(targetCenter);
            this.setState((prevState) => {
                let interpolatedCoordinates = { ...prevState };
                for(let tracker of trackers) {
                    interpolatedCoordinates[tracker.id] = { latitude: tracker.latitude, longitude: tracker.longitude };
                }
                return { interpolatedCoordinates };
            });
        } else {
            let center = {
                centerLatitude: this.state.centerLatitude,
                centerLongitude: this.state.centerLongitude,
                latitudeSpan: this.state.latitudeSpan,
                longitudeSpan: this.state.longitudeSpan
            };
            if(this.centerTween) {
                this.centerTween.stop();
            }
            this.centerTween = new Tween(center)
                .to(targetCenter, 2000)
                .easing(Easing.Quadratic.InOut)
                .onUpdate(() => {
                    this.setState(center);
                })
                .start();

            trackers.forEach((tracker) => {
                if(tracker.id in this.trackerTweens) {
                    this.trackerTweens[tracker.id].stop();
                }
                if(this.state.interpolatedCoordinates[tracker.id] === undefined) {
                    this.setState((prevState) => {
                        let { interpolatedCoordinates } = prevState;
                        interpolatedCoordinates[tracker.id] = { latitude: tracker.latitude, longitude: tracker.longitude };
                        return { interpolatedCoordinates };
                    });
                    return;
                }
                this.tweenTrackerCoordinate(tracker);
            });
        }
    }

    tweenTrackerCoordinate(tracker) {
        let coordinate = this.state.interpolatedCoordinates[tracker.id]
        this.trackerTweens[tracker.id] = new Tween(coordinate)
            .to({ latitude: tracker.latitude, longitude: tracker.longitude }, 2000)
            .easing(Easing.Quadratic.InOut)
            .onUpdate(() => {
                this.setState((prevState) => {
                    let { interpolatedCoordinates } = prevState;
                    interpolatedCoordinates[tracker.id] = coordinate;
                    return { interpolatedCoordinates };
                });
            })
            .start();
    }

    render() {
        const trackers = this.props.trackers;
        const {
            interpolatedCoordinates,

            centerLatitude,
            centerLongitude,
            latitudeSpan,
            longitudeSpan
        } = this.state;

        const loading = (
            <Skeleton height={ this.state.height }/>
        );

        if(interpolatedCoordinates === null || interpolatedCoordinates.length === 0 || centerLatitude === null
            || centerLongitude === null || latitudeSpan === null || longitudeSpan === null) {
            return loading
        }

        return (
            <div ref={ this.props.innerRef }>
                <AppleMapsMap
                    loading={ loading }
                    latitude={ centerLatitude ? centerLatitude : 51.994459 }
                    longitude={ centerLongitude ? centerLongitude : 4.180937 }
                    spanLat={ latitudeSpan }
                    spanLong={ longitudeSpan }
                    height={ this.state.height }
                    width={ this.state.width }
                    autoAdjust
                >
                    { trackers.map((tracker) => {
                        // const { latitude, longitude } = interpolatedCoordinates[tracker.id];
                        const glyphImage = getAnnotationGlyphImage(tracker);
                        const glyphColor = getAnnotationGlyphColor(tracker);
                        return (
                            <Annotation
                                key={ tracker.id }
                                id={ tracker.id }
                                latitude={ interpolatedCoordinates[tracker.id] ? interpolatedCoordinates[tracker.id].latitude : tracker.latitude }
                                longitude={ interpolatedCoordinates[tracker.id] ? interpolatedCoordinates[tracker.id].longitude : tracker.longitude }
                                color={ tracker.color }
                                title={ tracker.name }
                                glyphImage={ glyphImage }
                                glyphColor={ glyphColor }
                            />
                        )
                    })}
                </AppleMapsMap>
            </div>
        );
    }

}

export default WidthProvider(LiveAppleMaps);
