import React, {
    useContext,
    useMemo,
    useState
} from "react";
import {
    AuthenticationPage,
    AuthenticationPageColumn,
    AuthenticationPageColumnFooter,
    AuthenticationPageFooter
} from "@zandor300/backoffice-framework";

import {
    AuthenticatedUserContext
} from "../App";
import Logo from "../components/Logo";
import Copyright from "../components/Copyright";

import appstore from "../img/appstore.svg";

function Login() {
    const authenticatedUserContext = useContext(AuthenticatedUserContext);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const onError = useMemo(() => {
        return (errorMessage) => {
            setError(errorMessage);
            setLoading(false);
        };
    }, []);
    const onSubmit = useMemo(() => {
        return (event) => {
            event.preventDefault();
            setError(null);
            setLoading(true);
            authenticatedUserContext.loginFunction({ email, password }, onError);
        };
    }, [authenticatedUserContext, email, password, onError]);

    return (
        <AuthenticationPage>
            <AuthenticationPageColumn>
                <div className="app-icon mb-2">
                    <Logo/>
                </div>
                <h3 className="mt-0 text-center text-muted mb-4">Login</h3>
                { error !== null && (
                    <div className="alert alert-danger">{ error }</div>
                )}
                <form onSubmit={ onSubmit }>
                    <div className="form-group">
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="Email"
                            disabled={ loading }
                            value={ email }
                            onChange={(event) => setEmail(event.target.value) }
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="password"
                            className="form-control"
                            id="password"
                            placeholder="Wachtwoord"
                            disabled={ loading }
                            value={ password }
                            onChange={(event) => setPassword(event.target.value) }
                        />
                    </div>
                    <button type="submit" className="btn btn-primary float-right">
                        Inloggen
                    </button>
                </form>
                <AuthenticationPageColumnFooter>
                    <a href="https://apps.apple.com/nl/app/car-tracker/id1439127127" target="_blank" rel="noopener noreferrer">
                        <img src={ appstore } height="60" alt="App Store badge"/>
                    </a>
                </AuthenticationPageColumnFooter>
            </AuthenticationPageColumn>
            <AuthenticationPageFooter>
                <Copyright/>
            </AuthenticationPageFooter>
        </AuthenticationPage>
    );
}

export default React.memo(Login);
