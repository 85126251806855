import React from 'react';
import {
    Route,
    Redirect
} from "react-router-dom";

function PrivateRoute({ children, authenticated, target = "/login", ...rest }) {
    return (
        <Route { ...rest } render={ ({ location }) =>
            authenticated ? (
                children
            ) : (
                <Redirect to={ { pathname: target, state: { from: location } } }/>
            )
        }/>
    );
}

export default PrivateRoute;
