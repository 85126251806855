import React from "react";
import {
    Trans
} from "react-i18next";
import {
    Sidebar,
    SidebarDivider,
    SidebarLink
} from "@zandor300/backoffice-framework";

import Logo from "./Logo";

function BackofficeSidebar() {
    return (
        <Sidebar
            logo={
                <Logo/>
            }
        >
            <SidebarLink
                to="/live"
                icon="fas fa-car"
            >
                <Trans i18nKey="live"/>
            </SidebarLink>
            <SidebarLink
                to="/trips"
                icon="fas fa-route"
            >
                <Trans i18nKey="trips"/>
            </SidebarLink>
            <SidebarDivider/>
            <SidebarLink
                to="/settings"
                icon="fas fa-cog"
            >
                <Trans i18nKey="settings"/>
            </SidebarLink>
        </Sidebar>
    );
}

export default BackofficeSidebar;
