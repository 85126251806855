import React, {
    useEffect,
    useState
} from "react";
import axios from "axios";
import moment from "moment";
import {
    Map,
    InfoWindow,
    Marker,
    Circle,
    GoogleApiWrapper
} from "@zandor300/google-maps-react";
import {
    withTranslation
} from "react-i18next";

import useWindowSize from "../hooks/WindowSize";

function GoogleMapsMap({
    google,
    trackers,
    selectedMarker,
    didSelectMarker
}) {
    let windowSize = useWindowSize();
    let mapStyle = {
        position: "relative",
        width: "100%",
        height: Math.max(400, windowSize.height - 200) + "px"
    }

    const [bounds, setBounds] = useState(new google.maps.LatLngBounds());

    useEffect(() => {
        let newBounds = new google.maps.LatLngBounds();
        if (trackers !== null) {
            for (let i = 0; i < trackers.length; i++) {
                let tracker = trackers[i];
                newBounds.extend({ lat: tracker.latitude, lng: tracker.longitude });
            }
        }
        setBounds(newBounds);
    }, [trackers, google]);

    return (
        <Map google={ google } containerStyle={ mapStyle } style={ mapStyle }
             zoom={ 8 } initialCenter={{ lat: 52.106638, lng: 5.459964 }} bounds={ bounds }>

            { trackers !== null && trackers.map( tracker => (
                <Marker key={ tracker.id } onClick={ didSelectMarker.bind(this) } tracker={ tracker }
                        name={ tracker.name } position={{ lat: tracker.latitude, lng: tracker.longitude }}
                        icon={ axios.defaults.baseURL + "/getTrackerMarkerIcon?trackerId=" + tracker.id }
                />
            ))}
            { trackers !== null && trackers.map( tracker => (
                <Circle key={ tracker.id } center={{ lat: tracker.latitude, lng: tracker.longitude }}
                        radius={ tracker.latitudeError > tracker.longitudeError ? tracker.latitudeError : tracker.longitudeError }
                        fillColor={ tracker.color } strokeColor={ tracker.color }/>
            ))}

            <InfoWindow google={ google } visible={ selectedMarker !== null } marker={ selectedMarker }
                        onClose={ () => didSelectMarker(null, null, null) }>
                <div>
                    { (selectedMarker !== null && selectedMarker.tracker !== null) && (
                        <React.Fragment>
                            <b>{ selectedMarker.tracker.name }</b>
                            <br/>
                            <span>Snelheid: { Math.round(selectedMarker.tracker.speed * 10) / 10 } km/h</span>
                            <br/>
                            <span>Laatst bijgewerkt: { moment(selectedMarker.tracker.lastUpdate).calendar() }</span>
                        </React.Fragment>
                    )}
                </div>
            </InfoWindow>
        </Map>
    )
}

export default withTranslation()(GoogleApiWrapper((props) => ({
    apiKey: "AIzaSyBz81VI4DMlqFImUhQsM961TIqIAls2UDE",
    language: props.i18n.language
}))(GoogleMapsMap));
