import React from "react";
import axios from "axios";
import moment from "moment";
import fileDownload from "js-file-download";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    Button,
    Spinner
} from "react-bootstrap";
import {
    Trans
} from "react-i18next";
import {
    Title
} from "@zandor300/backoffice-framework";

import Loading from "../../components/Loading";

class TripsOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            trackers: null,
            error: null,

            exportingTripsTracker: null
        }
    }

    componentDidMount() {
        this.getTrackers();
    }

    getTrackers() {
        this.setState({ error: null });
        axios.get("/getTrackers")
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ trackers: response.data.trackers });
                } else {
                    if(response.data.error === "SESSION_EXPIRED") {
                        this.setState({ error: "Sessie is verlopen." });
                        window.location = "/";
                    } else {
                        this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                    }
                }
            })
            .catch((error) => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            })
    }

    exportTrips(tracker) {
        this.setState({ exportingTripsTracker: tracker });
        axios.post("/exportTrips", { trackerId: tracker.id }, { responseType: "blob" })
            .then((response) => {
                let fileNameBuilder = [`Ritten ${tracker.name}`];
                if(tracker.licensePlate) {
                    fileNameBuilder.push(tracker.licensePlate);
                }
                fileNameBuilder.push(moment().format("YYYYMMDD HHmmss"));
                fileDownload(
                    response.data,
                    fileNameBuilder.join(" - ") + ".xlsx"
                );
            })
            .catch(() => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            })
            .finally(() => {
                this.setState({ exportingTripsTracker: null });
            })
    }

    render() {
        const {
            error,
            trackers,
            exportingTripsTracker
        } = this.state;
        return (
            <React.Fragment>
                <Title preTitle={ <Trans i18nKey="overview"/> }>
                    <Trans i18nKey="trips"/>
                </Title>

                { error !== null ? (
                    <Alert variant="danger">{ error }</Alert>
                ) : !trackers ? (
                    <Loading/>
                ) : (
                    <div className="row">
                        { trackers.map(tracker => (
                            <div className="col-md-3 mb-4" key={ tracker.id }>
                                <div className="card" key={ tracker.id }>
                                    <div className="card-body">
                                        <h5 className="card-title mb-1">
                                            { tracker.name }
                                        </h5>
                                        <p className="text-muted mt-0" style={{ fontSize: "0.9rem", fontWeight: 500 }}>
                                            { tracker.licensePlate }
                                        </p>
                                        <div className="d-flex">
                                            <Link
                                                to={ "/trips/" + tracker.id }
                                                className="btn btn-primary btn-sm mr-2"
                                            >
                                                <Trans i18nKey="showTrips"/>
                                            </Link>
                                            { tracker.allowedToExport && (
                                                <Button
                                                    variant="secondary"
                                                    size="sm"
                                                    onClick={ () => this.exportTrips(tracker) }
                                                    disabled={ exportingTripsTracker !== null }
                                                >
                                                    { exportingTripsTracker === tracker && (
                                                        <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                                                    )}
                                                    <Trans i18nKey="exportTrips"/>
                                                </Button>
                                            )}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </React.Fragment>
        )
    }
}

export default TripsOverview;
