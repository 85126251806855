import React, {
    useState
} from "react";
import axios from "axios";
import {
    Alert
} from "react-bootstrap";
import {
    Trans,
    useTranslation
} from "react-i18next";
import {
    Title
} from "@zandor300/backoffice-framework";

function PasswordRequirementCheck({ state, children }) {
    return (
        <small className={ state ? "text-success" : "text-danger" }>
            { state ? (
                <i className="fas fa-check-circle fa-fw"/>
            ) : (
                <i className="fas fa-times-circle fa-fw"/>
            )}
            <span className="ml-1">
                { children }
            </span>
        </small>
    )
}

function Settings() {
    let [error, setError] = useState(null);
    let [success, setSuccess] = useState(null);
    let [password, setPassword] = useState("");
    let [passwordConfirm, setPasswordConfirm] = useState("");

    let changePassword = (e) => {
        e.preventDefault();
        setError(null);
        if(password.length < 8) {
            setError("Wachtwoord is te kort.");
            return;
        }
        if(password !== passwordConfirm) {
            setError("Wachtwoorden zijn niet hetzelfde.");
            return;
        }
        axios.post("/changePassword", { password: password })
            .then((response) => {
                if(response.data.valid) {
                    setSuccess((
                        <React.Fragment>
                            Wachtwoord veranderd. Nu zal er opnieuw ingelogd moeten worden.{" "}
                            <a href="/login">Klik hier om dat te doen</a>.
                        </React.Fragment>
                    ))
                } else {
                    if(response.data.error === "PASSWORD_TOO_SHORT") {
                        setError("Wachtwoord is te kort.")
                    } else {
                        setError("Er is een fout opgetreden. " + response.data.error);
                    }
                }
            })
            .catch((error) => {
                setError("Er is een fout opgetreden.");
            });
    }

    let canSubmit = password === passwordConfirm && password.length >= 8;

    const [translation] = useTranslation();

    return (
        <React.Fragment>
            <Title preTitle={ <Trans i18nKey="overview"/> }>
                <Trans i18nKey="settings"/>
            </Title>
            { success !== null && (
                <Alert variant="success">{ success }</Alert>
            )}
            { error !== null && (
                <Alert variant="danger">{ error }</Alert>
            )}
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title"><Trans i18nKey="changePassword"/></h5>
                    <form onSubmit={ changePassword.bind(this) }>
                        <div className="form-group">
                            <label htmlFor="password"><Trans i18nKey="newPassword"/></label>
                            <input type="password" className="form-control" id="password" name="password" placeholder={ translation("newPassword") } required
                                   value={ password } onChange={ (event) => setPassword(event.target.value) }/>
                            <PasswordRequirementCheck state={ password.length >= 8 }>
                                <Trans i18nKey="passwordRequirementLength"/>
                            </PasswordRequirementCheck>
                        </div>

                        <div className="form-group">
                            <label htmlFor="passwordConfirm"><Trans i18nKey="repeatPassword"/></label>
                            <input type="password" className="form-control" id="passwordConfirm" name="passwordConfirm" placeholder={ translation("repeatPassword") } required
                                   value={ passwordConfirm } onChange={ (event) => setPasswordConfirm(event.target.value) }/>
                            <PasswordRequirementCheck state={ password.length > 0 && password === passwordConfirm }>
                                <Trans i18nKey="passwordRequirementMatch"/>
                            </PasswordRequirementCheck>
                        </div>

                        <input type="submit" className="btn btn-primary float-right" disabled={ !canSubmit } value={ translation("save") }/>
                    </form>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Settings;
