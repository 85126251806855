import React from "react";
import {
    PageContainer,
    PageContent,
    PageFooter
} from "@zandor300/backoffice-framework";

function BackofficePageContainer({ children }) {
    return (
        <PageContainer>
            <PageContent>
                { children }
            </PageContent>
            <PageFooter>
                CarTracker
            </PageFooter>
        </PageContainer>
    );
}

export default React.memo(BackofficePageContainer);
