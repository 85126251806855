import React from 'react';
import {
    BrowserRouter as Router,
    Switch
} from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import { withTranslation } from "react-i18next";

import PrivateRoute from "./utilities/PrivateRoute";

import Login from "./pages/Login"
import Panel from "./pages/Panel";
import Loading from "./components/Loading";

/**
 * undefined means authentication has not been checked.
 * null means not authenticated.
 * filled object means authenticated.
 * @type {React.Context<null>}
 */
export const AuthenticatedUserContext = React.createContext(null);

class App extends React.Component {
    state = {
        authenticatedUser: { user: undefined, loginFunction: this.login.bind(this), logoutFunction: this.logout.bind(this) }
    };

    componentDidMount() {
        this.getSession();
    }

    login(state, onErrorOccurred) {
        axios.post("/login", { email: state.email, password: state.password, useCookie: true })
            .then((response) => {
                if(response.data.valid) {
                    this.setState(previousState => {
                        return { authenticatedUser: { ...previousState.authenticatedUser, user: response.data.user } }
                    })
                } else {
                    if(response.data.error === "INVALID_CREDENTIALS") {
                        onErrorOccurred("Foutieve gebruikersnaam of wachtwoord.");
                    } else {
                        onErrorOccurred("Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")");
                    }
                }
            })
            .catch((error) => {
                onErrorOccurred("Er ging iets fout. Probeer het later opnieuw.");
            })
    }

    getSession() {
        axios.get("/getSession")
            .then((response) => {
                if(response.data.valid) {
                    this.setState(previousState => {
                        return { authenticatedUser: { ...previousState.authenticatedUser, user: response.data.user } }
                    })
                } else {
                    this.setState(previousState => {
                        return { authenticatedUser: { ...previousState.authenticatedUser, user: null } }
                    })
                }
            })
            .catch((error) => {
                this.setState(previousState => {
                    return { authenticatedUser: { ...previousState.authenticatedUser, user: null } }
                })
            })
    }

    logout() {
        axios.get("/logout")
            .then((response) => {
                if(response.data.valid) {
                    this.setState(previousState => {
                        return { authenticatedUser: { ...previousState.authenticatedUser, user: null } }
                    });
                }
            })
            .catch((error) => {
                // TODO: Display pop-over error message.
            });
    }

    setLanguage(language) {
        this.props.i18n.changeLanguage(language);
        moment.locale('nl');
    }

    render() {
        if (this.state.authenticatedUser.user === undefined) {
            return (
                <Loading/>
            );
        } else {
            return (
                <AuthenticatedUserContext.Provider value={ this.state.authenticatedUser }>
                    <Router>
                        <Switch>

                            <PrivateRoute authenticated={ this.state.authenticatedUser.user === null } target="/live" path="/login">
                                <Login/>
                            </PrivateRoute>

                            <PrivateRoute authenticated={ this.state.authenticatedUser.user !== null } path="/">
                                <Panel language={ this.props.i18n.language } setLanguage={ this.setLanguage.bind(this) }/>
                            </PrivateRoute>

                        </Switch>
                    </Router>
                </AuthenticatedUserContext.Provider>
            );
        }
    }
}

export default withTranslation()(App);
