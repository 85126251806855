import React from "react";
import useWindowSize from "../hooks/WindowSize";

function WidthProvider(Component) {
    return function ComposedComponent(props) {
        const innerRef = React.useRef();
        const windowSize = useWindowSize();

        const [width, setWidth] = React.useState(1280);

        React.useEffect(() => {
            const node = innerRef.current;
            if(node instanceof HTMLElement && node.offsetWidth) {
                setWidth(node.offsetWidth);
            }
        }, [windowSize, innerRef]);

        return (
            <div style={{ overflowX: "hidden" }}>
                <Component
                    width={ width }
                    innerRef={ innerRef }
                    { ...props }
                />
            </div>
        )
    }
}

export default WidthProvider;
