import React from "react";
import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import {
    SidebarManager
} from "@zandor300/backoffice-framework";

import BackofficeNavbar from "../components/BackofficeNavbar";
import BackofficeSidebar from "../components/BackofficeSidebar";
import BackofficePageContainer from "../components/BackofficePageContainer";
import TripsOverview from "./panel/TripsOverview";
import Trips from "./panel/Trips";
import {
    AppleMapsManager
} from "../components/apple-maps/AppleMapsManager";

import Live from "./panel/Live";
import Settings from "./panel/Settings";

function Panel({ language, setLanguage }) {
    return (
        <AppleMapsManager>
            <SidebarManager>
                <BackofficeNavbar language={ language } setLanguage={ setLanguage }/>
                <BackofficeSidebar/>

                <BackofficePageContainer>
                    <Switch>
                        <Route path="/live" exact>
                            <Live/>
                        </Route>

                        <Route path="/live-google" exact>
                            <Live googleMaps/>
                        </Route>

                        <Route path="/trips" exact>
                            <TripsOverview/>
                        </Route>

                        <Route path="/trips/:trackerId" exact>
                            <Trips/>
                        </Route>

                        <Route path="/settings" exact>
                            <Settings/>
                        </Route>

                        <Route path="/">
                            <Redirect
                                to={ {
                                    pathname: "/live",
                                    state: { from: window.location.href }
                                } }
                            />
                        </Route>
                    </Switch>
                </BackofficePageContainer>
            </SidebarManager>
        </AppleMapsManager>
    );
}

export default Panel;
