import React, {
    useContext, useEffect
} from "react";
import {
    AppleMaps
} from "@zandor300/react-apple-mapkitjs";
import {
    Alert
} from "react-bootstrap";

import AppleMapsContext from "./AppleMapsManager";
import Loading from "../Loading";

function AppleMapsMap({ children, loading = (<Loading/>), ...props }) {
    const appleMapsContext = useContext(AppleMapsContext);

    useEffect(() => {
        const timerFunction = () => {
            appleMapsContext.refreshTokenIfNeeded();
        }
        timerFunction();
        const intervalId = setInterval(timerFunction, 60 * 1000);
        return () => clearInterval(intervalId);
    }, [appleMapsContext]);

    if(appleMapsContext.error) {
        return (
            <Alert variant="danger">{ appleMapsContext.error }</Alert>
        )
    }
    if(!appleMapsContext.token) {
        return loading
    }
    return (
        <AppleMaps
            { ...props }
            token={ appleMapsContext.token }
        >
            { children }
        </AppleMaps>
    )
}

export default React.memo(AppleMapsMap);
