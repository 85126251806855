import React, {
    useContext
} from "react";
import {
    NavDropdown
} from "react-bootstrap";
import {
    Trans
} from "react-i18next";
import {
    Navbar
} from "@zandor300/backoffice-framework";

import {
    AuthenticatedUserContext
} from "../App";

function BackofficeNavbar({ setLanguage, language }) {
    const authenticatedUserContext = useContext(AuthenticatedUserContext);

    const user = authenticatedUserContext.user;

    return (
        <Navbar>
            <NavDropdown title={ language.toUpperCase() } id="basic-nav-dropdown" className="d-none d-lg-inline-block" alignRight>
                <NavDropdown.Item onClick={ () => setLanguage("en") }>English</NavDropdown.Item>
                <NavDropdown.Item onClick={ () => setLanguage("nl") }>Nederlands</NavDropdown.Item>
                <NavDropdown.Item onClick={ () => setLanguage("de") }>Deutsche</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={ user && user.name } id="user" alignRight>
                <NavDropdown.Item onClick={ authenticatedUserContext.logoutFunction }>
                    <Trans i18nKey="logout"/>
                </NavDropdown.Item>
            </NavDropdown>
        </Navbar>
    );
}

export default React.memo(BackofficeNavbar);
