import React from "react";

function Logo() {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 940 1024"
             style={{ enableBackground: "new 0 0 940 1024" }}>
            <path style={{ opacity: 0.5, fill: "white" }}
                  d="M470,0C286.7,0,120.7,74.3,0.6,194.4l117.5,117.5c90.1-90.1,214.6-146,351.8-146s261.7,55.8,351.8,146
	l117.5-117.5C819.3,74.3,653.3,0,470,0z M470,232.3c-118.9,0-226.8,48.4-304.9,126.5l117.3,117.3c48.1-48.1,114.4-77.8,187.7-77.8
	s139.6,29.8,187.7,77.8l117.3-117.3C696.8,280.7,588.9,232.3,470,232.3z M470,464.6c-54.9,0-104.6,22.4-140.7,58.4L470,663.8
	L610.7,523C574.6,487,524.9,464.6,470,464.6z"/>
            <path style={{ fill: "white" }}
                  d="M861.1,589.8l-71.8-205.2c-19.6-55.9-72.6-93.5-131.8-93.5H282.6c-59.3,0-112.2,37.6-131.8,93.5L79,589.8
	C40.6,615,16.3,658.6,16.3,706.6v247.6c0,38.6,31.2,69.8,69.8,69.8s69.8-31.2,69.8-69.8v-44c0,0,184.3,9.1,314.1,9.1
	s314.1-9.1,314.1-9.1v44c0,38.6,31.2,69.8,69.8,69.8s69.8-31.2,69.8-69.8V706.6C923.7,658.6,899.4,615,861.1,589.8z M176.7,521.9
	l40-114.3c9.8-28,36.2-46.7,65.9-46.7h374.9c29.7,0,56.1,18.7,65.9,46.7l40,114.3c6.3,18-8.7,36.1-27.5,33
	c-68.4-11.1-156.4-19.5-265.8-19.5s-197.4,8.5-265.8,19.5C185.4,558,170.4,539.9,176.7,521.9z M173.4,779.7
	c-28.9,0-52.3-23.5-52.3-52.3s23.5-52.3,52.3-52.3s52.3,23.5,52.3,52.3C225.7,756.3,202.3,779.7,173.4,779.7z M574.7,744.8H365.3
	c-19.3,0-34.9-15.6-34.9-34.9S346,675,365.3,675h209.4c19.3,0,34.9,15.6,34.9,34.9S594,744.8,574.7,744.8z M766.6,779.7
	c-28.9,0-52.3-23.5-52.3-52.3s23.5-52.3,52.3-52.3s52.3,23.5,52.3,52.3C819,756.3,795.5,779.7,766.6,779.7z"/>
        </svg>
    )
}

export default Logo;
