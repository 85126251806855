import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {
    initReactI18next
} from "react-i18next";
import Backend from "i18next-http-backend";

import languageDE from "./localization/de.json";
import languageEN from "./localization/en.json";
import languageNL from "./localization/nl.json";

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            de: languageDE,
            en: languageEN,
            nl: languageNL
        },
        /* default language when load the website in browser */
        lng: "en",
        /* When react i18next not finding any language to as default in borwser */
        fallbackLng: "en",
        /* debugger For Development environment */
        debug: true,
        ns: ["translations"],
        defaultNS: "translations",
        keySeparator: ".",
        interpolation: {
            escapeValue: false,
            formatSeparator: ","
        },
        react: {
            wait: true,
            bindI18n: 'languageChanged loaded',
            bindStore: 'added removed',
            nsMode: 'default'
        }
    });

export default i18n;
