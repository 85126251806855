import React from "react";
import axios from "axios";
import {
    Alert
} from "react-bootstrap";
import {
    Trans
} from "react-i18next";
import {
    Title
} from "@zandor300/backoffice-framework";

import GoogleMapsMap from "../../components/GoogleMapsMap";
import LiveIndicator from "../../components/LiveIndicator";
import LiveAppleMaps from "../../components/LiveAppleMaps";

class Live extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            trackers: null,
            error: null,

            selectedMarker: null
        }
        this.onTimerUpdate = this.onTimerUpdate.bind(this);
    }

    componentDidMount() {
        this.getTrackers();
        this.timer = setInterval(this.onTimerUpdate, 5000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    onTimerUpdate() {
        this.getTrackers();
    }

    getTrackers() {
        if(this.loading) {
            console.log("Skipping loading, still loading from previous timer run.")
            return;
        }
        this.loading = true;

        this.setState({ error: null });
        axios.get("/getTrackers")
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ trackers: response.data.trackers });
                } else {
                    if(response.data.error === "SESSION_EXPIRED") {
                        this.setState({ error: "Sessie is verlopen." });
                        window.location = "/";
                    } else {
                        this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                    }
                }
            })
            .catch(() => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            })
            .finally(() => {
                this.loading = false;
            });
    }

    didSelectMarker(props, marker, e) {
        this.setState({ selectedMarker: marker });
    }

    render() {
        return (
            <React.Fragment>
                <Title preTitle={
                    <Trans i18nKey="overview"/>
                }>
                    <div className="d-flex">
                        <Trans i18nKey="live"/>
                        <LiveIndicator className="ml-2 mt-2"/>
                    </div>
                </Title>
                { this.state.error !== null && (
                    <Alert variant="danger">{ this.state.error }</Alert>
                )}
                { this.props.googleMaps ? (
                    <GoogleMapsMap
                        trackers={ this.state.trackers }
                        selectedMarker={ this.state.selectedMarker }
                        didSelectMarker={ this.didSelectMarker.bind(this) }
                    />
                ) : (
                    <LiveAppleMaps
                        trackers={ this.state.trackers }
                    />
                )}
            </React.Fragment>
        )
    }
}

export default Live;
