import React from "react";
import axios from "axios";
import moment from "moment";
import {
    Link,
    withRouter
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import {
    Trans
} from "react-i18next";
import {
    Title
} from "@zandor300/backoffice-framework";
import Skeleton from "react-loading-skeleton";

class TripsOverview extends React.Component {
    state = {
        trips: null,
        tracker: null,
        error: null,

        selectedMarker: null
    }

    constructor(props) {
        super(props);
        this.trackerId = props.match.params.trackerId;
    }

    componentDidMount() {
        this.getTrips(this.trackerId);
    }

    getTrips(trackerId) {
        this.setState({ error: null });
        axios.post("/getTrips", { trackerId: trackerId })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ trips: response.data.trips, tracker: response.data.tracker });
                } else {
                    if(response.data.error === "SESSION_EXPIRED") {
                        this.setState({ error: "Sessie is verlopen." });
                        window.location = "/";
                    } else {
                        this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                    }
                }
            })
            .catch((error) => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            })
    }

    getUrlForTrip(trackerId, tripId) {
        return "/trips/" + trackerId + "/" + tripId;
    }

    render() {
        return (
            <React.Fragment>
                <Title preTitle={ <Trans i18nKey="detail"/> }>
                    { this.state.tracker !== null ? this.state.tracker.name : <Trans i18nKey="trips"/> }
                </Title>
                <table className="table table-hover table-vertical-align table-sm">
                    <thead>
                    <tr>
                        <th><Trans i18nKey="date"/></th>
                        <th><Trans i18nKey="startLocation"/></th>
                        <th><Trans i18nKey="endLocation"/></th>
                    </tr>
                    </thead>

                    <tbody>
                    { this.state.error !== null ? (
                        <tr className="text-danger">
                            <td colSpan={ 3 }>
                                <Alert variant="danger">{ this.state.error }</Alert>
                            </td>
                        </tr>
                    ) : this.state.trips === null || this.state.tracker === null ? (
                        <React.Fragment>
                            { [...Array(20)].map((element, index) => (
                                <tr key={ index }>
                                    <td>
                                        <Skeleton width={ 175 }/>
                                    </td>
                                    <td>
                                        <Skeleton width={ 150 }/>
                                    </td>
                                    <td>
                                        <Skeleton width={ 150 }/>
                                    </td>
                                </tr>
                            ))}
                        </React.Fragment>
                    ) : this.state.trips.map( trip => (
                        <tr key={ trip.id } className="table-clickable-row">
                            <td>
                                <Link to={ this.getUrlForTrip(this.trackerId, trip.id) }>
                                    { moment(trip.registeredDate).calendar() }
                                </Link>
                            </td>
                            <td>
                                <Link to={ this.getUrlForTrip(this.trackerId, trip.id) }>
                                    { trip.startLocation || <Trans i18nKey="unknown"/> }
                                </Link>
                            </td>
                            <td>
                                <Link to={ this.getUrlForTrip(this.trackerId, trip.id) }>
                                    { trip.finishLocation || <Trans i18nKey="unknown"/> }
                                </Link>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>

            </React.Fragment>
        )
    }
}

export default withRouter(TripsOverview);
